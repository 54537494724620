const artworkCardImage = (artwork: Queries.DatoCmsArtwork) => {
  if (artwork) {
    if (
      artwork.framedImage &&
      artwork.framedImage !== null &&
      artwork.unframedImage &&
      artwork.unframedImage !== null
    ) {
      if (artwork.displayFramedImage) {
        return artwork.framedImage
      } else {
        return artwork.unframedImage
      }
    } else if (artwork.unframedImage && artwork.unframedImage !== null) {
      return artwork.unframedImage
    } else if (artwork.framedImage && artwork.framedImage !== null) {
      return artwork.framedImage
    }
  }
  return undefined
}

export default artworkCardImage
