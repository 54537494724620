// npm
import React, { memo, useState } from 'react'
import {
  Box,
  BoxProps,
  Button,
  Collapse,
  Container,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material'

// components
import SmallDivider from '@atoms/dividers/SmallDivider'

export interface SectionExpandableTextProps extends BoxProps {
  smallHeading: string
  leadText: string
  text: string
  expandLabel: string
  contractLabel: string
}

const Wrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  textAlign: 'center',
  paddingTop: theme.spacing(7),
  paddingBottom: theme.spacing(7),
  [theme.breakpoints.up('sm')]: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  [theme.breakpoints.up('md')]: {
    paddingTop: theme.spacing(9),
    paddingBottom: theme.spacing(9),
  },
  [theme.breakpoints.up('xl')]: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
  },
}))

const StyledButton = styled(Button)(({ theme }) => ({
  borderColor: '#d8d7d8',
  backgroundColor: theme.palette.common.white,
  fontFamily: theme.typography.fontFamilySerif,
  fontWeight: theme.typography.fontWeightRegular,
  textTransform: 'uppercase',
  fontSize: '1.5rem',
  letterSpacing: '0.3rem',
  lineHeight: 1,
  color: theme.palette.secondary.main,
  padding: '16px 30px',
  ['&:hover']: {
    backgroundColor: theme.palette.common.white,
  },
}))

const ButtonWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  ['&:before']: {
    content: '""',
    display: 'block',
    width: '100%',
    height: '1px',
    backgroundColor: '#d8d7d8',
    position: 'absolute',
    top: '50%',
  },
}))

const Icon = styled(Box)(({ theme }) => ({
  fontSize: '2.4rem !important',
  fontFamily: theme.typography.fontFamilySerif,
  fontWeight: theme.typography.fontWeightRegular,
}))

const SectionExpandableText = ({
  leadText,
  text,
  smallHeading,
  expandLabel,
  contractLabel,
  ...props
}: SectionExpandableTextProps) => {
  const theme = useTheme()
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'))
  const [expand, setExpand] = useState<boolean>(false)

  return (
    <Wrapper component="section" {...props}>
      <Container maxWidth={lgDown ? 'sm' : 'md'}>
        {smallHeading && (
          <Box
            typography="xsHeading"
            color="primary.main"
            component="h2"
            mb={3}
          >
            {smallHeading}
          </Box>
        )}
        <Box typography="leadTextSmall" mb={3}>
          {leadText}
        </Box>

        {text && (
          <>
            <Collapse in={expand}>
              <Box fontSize="1.8rem">{text}</Box>
              <SmallDivider sx={{ margin: `${theme.spacing(4)} auto 0` }} />
            </Collapse>
            <ButtonWrapper mt={4}>
              <StyledButton
                onClick={() => setExpand(!expand)}
                variant="outlined"
                size="large"
                startIcon={<Icon>{expand ? '-' : '+'}</Icon>}
              >
                {expand ? contractLabel : expandLabel}
              </StyledButton>
            </ButtonWrapper>
          </>
        )}
      </Container>
    </Wrapper>
  )
}

export default memo(SectionExpandableText)
