import artistName from './artistName'

interface Props {
  isKnown: boolean
  knownArtist: Queries.DatoCmsArtist | undefined
  unknownArtistDescription: string | undefined
}

const artworkArtistName = ({
  isKnown,
  knownArtist,
  unknownArtistDescription,
}: Props) => {
  if (isKnown && knownArtist) {
    return artistName({ artist: knownArtist })
  } else if (!isKnown && unknownArtistDescription) {
    return unknownArtistDescription
  }
  return undefined
}

export default artworkArtistName
