// npm
import React, { memo } from 'react'
import { Box, BoxProps, Container, Grid, styled } from '@mui/material'

// components
import SmallDivider from '@atoms/dividers/SmallDivider'
import ArtworkGrid from '@organisms/grid/ArtworkGrid'

export interface SectionArtworkGridProps extends BoxProps {
  heading: string
  artworks: Query.DatoCmsArtwork[]
  postArtworks: React.ReactNode
}

const Wrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  color: theme.palette.text.primary,
  position: 'relative',
  overflow: 'hidden',
  paddingTop: theme.spacing(5),
  paddingBottom: theme.spacing(6),
  [theme.breakpoints.up('sm')]: {
    paddingTop: theme.spacing(7),
    paddingBottom: theme.spacing(7),
  },
  [theme.breakpoints.up('md')]: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  [theme.breakpoints.up('lg')]: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
  },
  [theme.breakpoints.up('xl')]: {
    paddingTop: theme.spacing(12),
    paddingBottom: theme.spacing(10),
  },
}))

const SectionArtworkGrid = ({
  heading,
  artworks,
  postArtworks,
  ...props
}: SectionArtworkGridProps) => {
  return (
    <Wrapper component="section" {...props}>
      <Container maxWidth="xl">
        <Box
          typography="sectionHeadingLarge"
          component="h2"
          textAlign="center"
          mt={0}
          mb={2}
        >
          {heading}
        </Box>
        <SmallDivider sx={{ mx: 'auto', mb: { xs: 3, sm: 4, lg: 5, xl: 6 } }} />
        <ArtworkGrid artworks={artworks} />
        {postArtworks}
      </Container>
    </Wrapper>
  )
}

export default memo(SectionArtworkGrid)
