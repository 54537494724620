interface Props {
  yearOfBirth: number
  yearOfDeath?: number
}

const artistLifetime = ({ yearOfBirth, yearOfDeath }: Props) => {
  if (yearOfDeath) {
    return `(${yearOfBirth} - ${yearOfDeath})`
  }
  return `b. ${yearOfBirth}`
}

export default artistLifetime
