// npm
import React from 'react'
import { graphql, PageProps } from 'gatsby'
import { Box } from '@mui/material'

// root
import { pageContextProps } from '../../gatsby-node'
import Layout from '@organisms/global/Layout'

// components
import SectionArtworkGrid from '@molecules/sections/components/SectionArtworkGrid'
import SectionExpandableText from '@molecules/sections/components/SectionExpandableText'
import ArtistHero from '@organisms/hero/AristHero'

// helpers
import artistName from '@helpers/artistName'
import artistLifetime from '@helpers/artistLifetime'

interface Props {
  data: Queries.ArtistDetailQuery
  pageContext: PageProps & pageContextProps
}

const ArtworkDetail = ({ data, pageContext }: Props) => {
  const artist = data.datoCmsArtist as Queries.DatoCmsArtist
  const global = data.datoCmsArtistGlobal

  return (
    <Layout
      activeNavSlug={pageContext.activeNavSlug}
      metadata={{
        seoTags: artist.seoMetaTags,
        customTitle: artistName({ artist: artist }),
        // extraTitle: artwork.format.format,
        // customDescription: `${artwork.title} ${
        //   isKnown ? 'by ' + artwork.knownArtist.name + ' ' : ''
        // } - ${artwork.format.format}. ${artwork.category.name} ${
        //   artwork.availability.title
        // }. `,
      }}
    >
      <ArtistHero
        smallHeading={global.heroSmallHeading}
        artistName={artistName({ artist: artist })}
        image={artist.heroImage.gatsbyImageData}
      />
      <SectionArtworkGrid
        artworks={data.allDatoCmsArtwork.edges}
        heading={global.browseArtworksHeading}
      />
      <Box position="relative">
        <SectionExpandableText
          smallHeading={artistLifetime({
            yearOfBirth: artist.yearOfBirth,
            yearOfDeath: artist.yearOfDeath,
          })}
          leadText={artist.leadText}
          text={artist.text}
          expandLabel={global.expandButtonLabel}
          contractLabel={global.contractButtonLabel}
        />
      </Box>
    </Layout>
  )
}

export const query = graphql`
  query ArtistDetail($originalId: String!) {
    datoCmsArtistGlobal {
      browseArtworksHeading
      contractButtonLabel
      expandButtonLabel
      heroSmallHeading
      heroMoreButtonLabel
    }
    datoCmsArtist(originalId: { eq: $originalId }) {
      ...artistBase
      yearOfBirth
      yearOfDeath
      leadText
      text
      heroImage {
        gatsbyImageData(
          width: 1200
          layout: FULL_WIDTH
          imgixParams: { ar: "4:3", fit: "crop", crop: "focalpoint" }
        )
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    allDatoCmsArtwork(
      filter: { knownArtist: { originalId: { eq: $originalId } } }
      sort: {
        fields: [availability___position, meta___createdAt]
        order: [ASC, DESC]
      }
    ) {
      edges {
        node {
          ...artworkCard
        }
      }
    }
  }
`

export default ArtworkDetail
