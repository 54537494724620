// npm
import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Box, styled, useTheme } from '@mui/material'

// components
import SmallDivider from '@atoms/dividers/SmallDivider'
import ImageHoverFrame from '@atoms/media/ImageHoverFrame'
import InternalLink from '@atoms/links/InternalLink'

// constants
import { availability } from '@constants/availability'

// helpers
import artworkArtistName from '@helpers/artworkArtistName'
import artworkCardImage from '@helpers/artworkCardImage'

interface Props {
  artwork: Queries.DatoCmsArtwork
}

const StyledInternalLink = styled(InternalLink)(({ theme }) => ({
  display: 'block',
}))

const Name = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.fontFamilySerif,
  fontWeight: theme.typography.fontWeightLight,
  fontSize: '2.8rem',
  letterSpacing: '0.1rem',
  [theme.breakpoints.between('sm', 'md')]: {
    fontSize: '2.2rem',
  },
}))

const Title = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontWeight: theme.typography.fontWeightLight,
  fontSize: '1.8rem',
  [theme.breakpoints.between('sm', 'md')]: {
    fontSize: '1.6rem',
  },
  letterSpacing: '0.1rem',
}))

const Details = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.fontFamilySerif,
  fontWeight: theme.typography.fontWeightThin,
  fontSize: '1.4rem',
  letterSpacing: '0.2rem',
  textTransform: 'uppercase',
}))

const ArtworkCard = ({ artwork }: Props) => {
  const theme = useTheme()
  const image = artworkCardImage(artwork)
  const artistName = artworkArtistName({
    isKnown: artwork.isKnownArtist,
    knownArtist: artwork.knownArtist,
    unknownArtistDescription: artwork.unknownArtistDescription,
  })
  const isSold =
    artwork.availability.originalId === availability['sold'].originalId
  return (
    <StyledInternalLink
      slug={artwork.slug}
      apiKey={artwork.model.apiKey}
      prefixSlug={
        artwork.isKnownArtist && artwork.knownArtist.slug
          ? artwork.knownArtist.slug
          : artwork.category.slug
      }
    >
      {image && (
        <ImageHoverFrame>
          <GatsbyImage image={image.gatsbyImageData} alt="" />
        </ImageHoverFrame>
      )}
      <Box pr={1}>
        {artistName && <Name mt={2}>{artistName}</Name>}
        <Title mb={2}>
          {artwork.title}
          <Box
            sx={{
              fontFamily: theme.typography.fontFamilySerif,
              fontWeight: theme.typography.fontWeightRegular,
              fontStyle: 'italic',
            }}
          >
            {artwork.availability.title}
          </Box>
        </Title>
        <Box justifySelf="flex-end" pb={{ xs: 4, lg: 3 }}>
          <SmallDivider />
          <Details mt={2}>
            {artwork.format.format}
            {artwork.dimensions && <> - {artwork.dimensions}</>}
          </Details>
        </Box>
      </Box>
    </StyledInternalLink>
  )
}

export default ArtworkCard
