// npm
import React, { memo } from 'react'
import { Box, BoxProps, styled } from '@mui/material'

interface Props extends BoxProps {
  children: React.ReactNode
}

const Hover = styled(Box)(({ theme }) => ({
  display: 'block',
  background: theme.palette.secondary.main,
  overflow: 'hidden',
  width: '100%',
  position: 'relative',
  ['.gatsby-image-wrapper']: {
    width: '100%',
    height: '100%',
    opacity: 1,
    transform: 'scale(1.01)',
    transition: theme.transitions.create(['opacity', 'transform'], {
      duration: 1000,
    }),
  },
  ['.overlay']: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    ['&:before, &:after']: {
      position: 'absolute',
      top: '16px',
      right: '16px',
      bottom: '16px',
      left: '16px',
      content: '""',
      transition: theme.transitions.create(['opacity', 'transform'], {
        duration: 300,
      }),
      opacity: 0.5,
      transform: 'scale(1)',
      transitionDelay: '0s',
    },
    ['&:before']: {
      borderTop: `1px solid #fff`,
      borderBottom: '1px solid #fff',
      transform: 'scale(0, 1)',
    },
    ['&:after']: {
      borderRight: '1px solid #fff',
      borderLeft: '1px solid #fff',
      transform: 'scale(1, 0)',
    },
  },
  ['&:hover']: {
    ['.gatsby-image-wrapper']: {
      opacity: '0.7',
      transform: 'scale(1.08)',
    },
    ['.overlay']: {
      ['&:before, &:after']: {
        opacity: 1,
        transform: 'scale(1)',
        transitionDelay: '0.3s',
      },
    },
  },
}))

const ImageHoverFrame = ({ children, ...props }: Props) => (
  <Hover {...props}>
    {children}
    <div className="overlay" />
  </Hover>
)

export default memo(ImageHoverFrame)
