// npm
import React from 'react'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { Box, Container, Stack, styled, useTheme } from '@mui/material'

// components
import MoreButton, { MoreButtonProps } from '@atoms/buttons/MoreButton'
import FixedHeightHero from '@atoms/layout/FixedHeightHero'

interface Props {
  smallHeading: string
  artistName: string
  moreButtonProps: MoreButtonProps
  image: IGatsbyImageData
}

const ImageWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  overflow: 'hidden',
  width: '100%',
  height: '100%',
}))

const StyledGatsbyImage = styled(GatsbyImage)(({ theme }) => ({
  opacity: 0.6,
  width: '100%',
  height: '100%',
  top: '50%',
  left: '50%',
  transform: 'translate3d(-50%, -50%,0)',
}))

const ArtistHero = ({
  artistName,
  smallHeading,
  image,
  moreButtonProps,
}: Props) => {
  const theme = useTheme()
  return (
    <FixedHeightHero size="large" sx={{ position: 'relative', paddingTop: 0 }}>
      <ImageWrapper className="fixed-height">
        <StyledGatsbyImage image={image} alt="" loading="eager" />
      </ImageWrapper>
      <Container maxWidth="xl" sx={{ position: 'relative' }}>
        <Stack justifyContent="center" className="fixed-height">
          <Box component="h1" typography="xl" sx={{ m: 0 }} order={2}>
            {artistName}
          </Box>
          <Box component="h2" typography="smallHeading" sx={{ m: 0 }} order={1}>
            {smallHeading}
          </Box>
        </Stack>
        {/* <MoreButton
          sx={{
            position: 'absolute',
            bottom: theme.spacing(4),
            left: '50%',
            transform: 'translateX(-50%)',
          }}
          labelProps={{
            sx: {
              left: '50% !important',
              transform: 'translateX(-50%)',
              bottom: '140% !important',
              color: theme.palette.common.white,
              fontSize: '2.4rem !important',
              fontStyle: 'italic',
              fontWeight: theme.typography.fontWeightRegular,
              textTransform: 'none',
            },
          }}
          {...moreButtonProps}
        /> */}
      </Container>
    </FixedHeightHero>
  )
}

export default ArtistHero
