// npm
import React from 'react'
import { Grid, styled, useMediaQuery, useTheme } from '@mui/material'

// components
import ArtworkCard from '@molecules/cards/ArtworkCard'

type artworkNode = {
  node: Queries.DatoCmsArtwork
}

interface Props {
  artworks: artworkNode[]
}

const StyledGridItem = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'stretch',
}))

const ArtworkGrid = ({ artworks }: Props) => {
  const theme = useTheme()
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'))

  return (
    <Grid
      container
      spacing={lgDown ? 3 : 6}
      justifyContent={artworks.length < 4 ? 'center' : 'flex-start'}
    >
      {artworks.map((item: artworkNode) => {
        const artwork: Queries.DatoCmsArtwork = item.node
        return (
          <StyledGridItem
            item
            xs={12}
            sm={6}
            lg={4}
            xl={3}
            key={artwork.originalId}
          >
            <ArtworkCard artwork={artwork} />
          </StyledGridItem>
        )
      })}
    </Grid>
  )
}

export default ArtworkGrid
